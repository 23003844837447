.booking-calendar .rbc-month-view,
.booking-calendar .rbc-agenda-view {
  background-color: white;
  overflow: hidden;
  border-radius: var(--chakra-radii-lg);
}

.booking-calendar .rbc-header {
  padding: var(--chakra-space-1) var(--chakra-space-2);
  color: var(--color-ternary);
}

.booking-calendar .rbc-date-cell {
  font-weight: bold;
  padding: var(--chakra-space-2);
}

.booking-calendar .rbc-row-segment {
  margin-bottom: 1px;
}

.booking-calendar .rbc-event {
  background-color: var(--color-primary-pale);
  padding: 0;
  /* padding: 1px var(--chakra-space-2); */
  font-weight: var(--chakra-fontWeights-extrabold);
  /* border: 1px solid; */
}

/* .booking-calendar .rbc-day-bg {} */

.booking-calendar .rbc-day-bg:hover {
  background: var(--chakra-colors-secondary-50);
}

.booking-calendar .rbc-today {
  background-color: var(--color-primary-pale);
}

/* .booking-calendar .rbc-now {
  color: var(--color-primary-dark);
} */

/*
.booking-calendar .rbc-day-bg+.rbc-day-bg {
  border-left-color: var(--color-gray-light);
}
*/

.booking-calendar .rbc-event, .booking-calendar .rbc-day-slot .rbc-background-event {
  background-color: var(--color-primary);
  /* font-size: .8em; */
}

.booking-calendar .rbc-selected-cell {
  background-color: var(--chakra-colors-secondary-50);
}

/* .booking-calendar .editable-booking.rbc-selected {
  background-color: var(--chakra-colors-secondary-50);
} */

.booking-calendar .rbc-event:focus {
  outline: none;
}

/* .booking-calendar .readonly-booking {
  border-width: 1px;
  background-color: none;
  cursor: default;
} */

/** UGLY hack to prevent agenda view to be colored */
.rbc-agenda-view .rbc-agenda-table tr {
  background: none !important;
}

.booking-calendar .day-status,
.booking-calendar-legend .day-status {
  position: relative;
}

.booking-calendar .day-status:after,
.booking-calendar-legend .day-status:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: rotate(45deg);
  z-index: 1;
}

.booking-calendar .day-status:after {
  margin: 14px 7px;
}

.booking-calendar .day-status-3:after {
  /* High */
  background-color: var(--chakra-colors-light-200);
}

.booking-calendar .day-status-5 {
  background-color: var(--chakra-colors-danger-50);
}

.booking-calendar .day-status-5:after {
  /* Overload */
  background-color: var(--chakra-colors-danger-200);
}

.booking-calendar .day-status-4 {
  background-color: var(--chakra-colors-warning-50);
}

.booking-calendar .day-status-4:after {
  /* Full */
  background-color: var(--chakra-colors-warning-200);
}

.booking-calendar .day-status-3:after {
  /* High */
  background-color: var(--chakra-colors-light-200);
}

.booking-calendar .day-status-2:after {
  /* Medium */
  background-color: var(--chakra-colors-success-50);
}

.booking-calendar .day-status-1:after {
  /* Low */
  background-color: none;
}

/* .booking-calendar .day-status-0 {} */

/** Legend only */
.booking-calendar-legend .day-status {
  padding: 2px 6px 2px 28px;
  border-radius: 4px;
}

.booking-calendar-legend .day-status:after {
  margin: 5px 8px;
  left: 0;
}

/** Report view only */
.booking-calendar-report .day-status:after {
  display: none;
}

.booking-calendar-report .day-status-3 {
  /* High */
  background-color: var(--chakra-colors-light-50);
}
.booking-calendar-report .day-status-2 {
  /* Medium */
  background-color: var(--chakra-colors-success-50);
}
.DayPicker {
  /* Fix line heights when wrapped into modal*/
  line-height: 1;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day:focus:not(.DayPicker-Day--outside) {
  background-color: var(--color-primary-pale);
  outline: none;
}


.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: var(--chakra-colors-primary-50);
}

.DayPicker.Selectable .DayPicker-Day--today {
  background-color: var(--chakra-colors-secondary-50);
  color: var(--chakra-colors-secondary-700);
}

.DayPicker.Selectable .DayPicker-Day--selected {
  background-color: var(--chakra-colors-primary-500);
  color: var(--chakra-colors-primary-50);
}

.DayPicker.Selectable .DayPicker-Day--selected:hover {
  background-color: var(--chakra-colors-primary-400);
}

.DayPicker.Selectable .DayPicker-Day--selected:hover {
  background-color: var(--chakra-colors-primary-400);
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--outside {
  background: none;
}
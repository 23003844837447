@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?9v4man');
  src: url('./fonts/icomoon.eot?9v4man#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?9v4man') format('truetype'), url('./fonts/icomoon.woff?9v4man') format('woff'), url('./fonts/icomoon.svg?9v4man#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-bottom;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-left:before {
  content: "\e900";
}

.icon-add-circle:before {
  content: "\e901";
}

.icon-angle-down:before {
  content: "\e902";
}

.icon-angle-right:before {
  content: "\e903";
}

.icon-angle-small-down:before {
  content: "\e904";
}

.icon-angle-small-left:before {
  content: "\e905";
}

.icon-angle-small-right:before {
  content: "\e906";
}

.icon-angle-small-up:before {
  content: "\e907";
}

.icon-angle-up:before {
  content: "\e908";
}

.icon-calendar-day-outline:before {
  content: "\e909";
}

.icon-calendar-day:before {
  content: "\e90a";
}

.icon-calendar-list-outline:before {
  content: "\e90b";
}

.icon-calendar-list:before {
  content: "\e90c";
}

.icon-calendar-outline:before {
  content: "\e90d";
}

.icon-calendar-table-outline:before {
  content: "\e90e";
}

.icon-calendar-table:before {
  content: "\e90f";
}

.icon-calendar:before {
  content: "\e910";
}

.icon-camera:before {
  content: "\e911";
}

.icon-caret-down:before {
  content: "\e912";
}

.icon-caret-left:before {
  content: "\e913";
}

.icon-caret-right:before {
  content: "\e914";
}

.icon-caret-up:before {
  content: "\e915";
}

.icon-check:before {
  content: "\e916";
}

.icon-checkbox-empty:before {
  content: "\e917";
}

.icon-checkbox-mixed:before {
  content: "\e918";
}

.icon-checkbox:before {
  content: "\e919";
}

.icon-cogs:before {
  content: "\e91a";
}

.icon-cross-circle:before {
  content: "\e91b";
}

.icon-cross-small:before {
  content: "\e91c";
}

.icon-cross:before {
  content: "\e91d";
}

.icon-dashboard:before {
  content: "\e91e";
}

.icon-dots-horizontal:before {
  content: "\e91f";
}

.icon-dots-vertical:before {
  content: "\e920";
}

.icon-edit:before {
  content: "\e921";
}

.icon-grid:before {
  content: "\e922";
}

.icon-house-outline:before {
  content: "\e923";
}

.icon-house:before {
  content: "\e924";
}

.icon-menu-burger:before {
  content: "\e925";
}

.icon-minus-small:before {
  content: "\e926";
}

.icon-minus:before {
  content: "\e927";
}

.icon-pen:before {
  content: "\e928";
}

.icon-picture:before {
  content: "\e929";
}

.icon-plus-small:before {
  content: "\e92a";
}

.icon-plus:before {
  content: "\e92b";
}

.icon-portrait:before {
  content: "\e92c";
}

.icon-remove-circle:before {
  content: "\e92d";
}

.icon-search:before {
  content: "\e92e";
}

.icon-settings:before {
  content: "\e92f";
}

.icon-signal-0:before {
  content: "\e930";
}

.icon-signal-1:before {
  content: "\e931";
}

.icon-signal-2:before {
  content: "\e932";
}

.icon-signal-3:before {
  content: "\e933";
}

.icon-tent-outline:before {
  content: "\e934";
}

.icon-tent:before {
  content: "\e935";
}

.icon-trash:before {
  content: "\e936";
}

.icon-user-add:before {
  content: "\e937";
}

.icon-user-delete:before {
  content: "\e938";
}

.icon-user-remove:before {
  content: "\e939";
}

.icon-user:before {
  content: "\e93a";
}

.icon-users:before {
  content: "\e93b";
}